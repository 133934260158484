body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  #root {
    text-align: left;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  .welcome-section {
    width: 300px !important;
    text-align: left;
    margin: 0 auto !important;
  }

  .item {
    &.item-button {
      text-align: left;

      & > * {
        display: block;
      }

      .header {
        margin: 4px 0 10px 0;
      }

      .meta {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }

  .ui {
    &.menu {
      &.borderless {
        border: 0 !important;
      }
    }

    &.segment {
      &.no-horizontal-padding {
        padding-left: 0;
        padding-right: 0;
      }

      &.no-spacing {
        padding: 0;
        margin: 0;
      }
    }

    &.grid {
      .column {
        &.non-icon-column {
          width: calc(100% - 66px) !important;
        }
        &.icon-column {
          width: 66px !important;
        }
      }
    }

    &.form {
      .field.field input:-webkit-autofill {
        -webkit-text-fill-color: #000;
      }
    }

    &.inverted {
      &.checkbox {
        input:focus ~ label {
          color: rgba(255, 255, 255, 0.8) !important;
        }

        label:hover {
          color: rgba(255, 255, 255, 0.8) !important;
        }
      }

      &.button {
        box-shadow: 0 0 0 2px #666 inset !important;

        &:hover {
          background-color: black !important;
          color: #fff !important;
        }
      }

      &.menu {
        &.tabular {
          border-bottom: 1px solid #d4d4d5 !important;

          .item {
            &.active {
              border-bottom: 1px solid #1b1c1d;
              background-color: #1b1c1d;
            }
          }
        }

        .dropdown {
          .menu {
            background-color: #2d2d2d;

            .header {
              color: #999 !important;
            }

            .item {
              color: #fff !important;

              &.active {
                background-color: #242424 !important;
                color: #fff !important;
              }

              &:hover {
                background-color: #222 !important;
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #1b1c1d !important;
  }

  #welcome-panel {
    color: #fff;

    &.not-dark {
      color: inherit;
    }
  }
}
